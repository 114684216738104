.wrapper {
  display: flex;
  align-items: center;
  margin: 0;
}

.label {
  margin-right: 10px;
  font-size: 18px;
  text-align: right;
}

.input {
  width: 100%;
  height: 36px;
  padding: 10px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}
