.form {
  width: 1000px;
  margin: 50px auto 10px;
}

.form > * {
  margin-bottom: 15px;
}

.selectWrapper :global(.MuiInputBase-root.MuiInput-root) {
  width: 560px;
  padding-left: 10px;
}

.selectLabel {
  margin: 0;
  margin-right: 10px;
  font-size: 18px;
  text-align: right;
  width: 200px;
  display: inline-block;
}

.searchWrapper {
  display: flex;
  margin-bottom: 40px;
}
