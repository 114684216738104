.content {
  display: flex;
}

.rightBlock {
  width: 400px;
  margin-left: 15px;
}

.listItem {
  padding: 5px 10px;
  /* font-size: 15px; */
  font-weight: bold;
  margin-bottom: 5px;
  background-color: #eefaff;
  border: 1px solid #007ef0;
}

.listItem :global(span) {
  display: inline-block;
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}

.listItem :global(button) {
  margin-left: 10px;
}

.textArea {
  width: 400px;
  height: 250px;
  padding: 0 10px;
  resize: none;
}

.subtitle {
  font-weight: bold;
}

.dateFields {
  margin: 20px 0;
}

.dateFields > div {
  margin-right: 30px;
}

.select {
  width: 300px;
  margin-bottom: 10px;
}
