html,
body,
#root,
.App {
  height: 100%;
}

.App {
  display: flex;
  background-color: #f1ecec;
  padding-top: 84px;
  padding-bottom: 10px;
}

.App .header {
  text-align: right;
  padding: 10px 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.full-page-tabs + .tab-content {
  height: calc(100% - 42px);
  padding-top: 15px;
}

.height100 {
  height: 100%;
}

.height50 {
  height: 50%;
}

.page-content {
  height: 100%;
  width: 100%;
  margin: 0 10px 10px;
  background-color: #fff;
}

.full-height-tab {
  height: calc(100% - 48px);
}

