.placeholder {
  text-align: center;
  padding-top: 100px;
}

.placeholder.mini {
  padding-top: 10px;
}

.image {
  width: 250px;
}

.mini .image {
  width: 140px;
}

.title {
  color: #8a96a3;
  font-weight: bold;
  font-size: 30px;
  margin: 5px 0 20px;
}

.text {
  font-size: 18px;
}
