.panel {
  height: 100%;
  overflow: hidden;
  /* border-radius: 5px;
  /* box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 20%);
  /* box-shadow: 0 2px 8px rgb(18 17 36 / 4%), 0 4px 32px rgb(18 17 36 / 8%); */
}

.header {
  height: 40px;
}

.bodyWithHeader {
  height: calc(100% - 40px);
}

.body {
  height: 100%;
}

.panel :global .accordion {
  overflow: hidden;
}
