:global .App header {
  z-index: 1300;
}

.title {
  display: flex;
  flex-grow: 1;
}

:global(header) .select {
  color: #fff;
  margin-right: 15px;
}

:global(header) .select:before {
  border-color: #fff;
}

:global(header) .select:hover:before {
  border-color: #fff !important;
}

:global(header) .select svg {
  color: #fff;
}

:global(header) .langButton {
  color: #fff;
  width: 23px;
  height: 23px;
}

.login {
  margin-right: 30px;
  margin-left: -10px;
  font-weight: bold;
  font-size: 18px;
}

.rightBlock {
  align-items: center;
  display: flex;
}

.companyLock {
  margin-right: 15px;
  font-weight: bold;
  font-style: italic;
}

.headerLogo {
  color: #fff;
  text-decoration: none;
}
