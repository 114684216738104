.userCard {
  position: relative;
  width: 345px;
  margin-left: 20px;
  flex-shrink: 0;
}

.userCardMedia {
  height: 140px;
}

.userCard .avatar {
  position: absolute;
  top: 30px;
  left: 50%;
  margin-left: -40px;
  height: 80px;
  width: 80px;
}

.tabContent {
  height: calc(100% - 54px);
}

:global .profile-page .ScrollbarsCustom-Content {
  overflow-x: hidden !important;
}

.title {
  position: relative;
  text-align: center;
  margin: 10px 0 15px;
}

.headerButton {
  position: absolute !important;
  left: 10px;
}

.headerButton_right {
  position: absolute !important;
  right: 10px;
}

.sections {
  display: flex;
  height: 100%;
  justify-content: space-around;
}

.sectionsReverse .leftSection {
  width: 33%;
}

.sectionsReverse .rightSection {
  width: 63%;
}

.leftSection {
  width: 63%
}

.rightSection {
  width: 33%;
}

.sectionHeader {
  height: 57px;
  background-color: #fafafa;
  color: #000000de;
  font-weight: bolder;
  line-height: 1.5rem;
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

.contentPadding {
  padding: 20px;
}

.infoPanel {
  height: 100%;
}

.infoPanelHeader {
  display: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.buttonPanel {
  float: right;
  margin-right: 15px;
}
