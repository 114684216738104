.root {
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 0;

  :global(.mapWrapper) {
    position: relative;
    width: 270px;
    height: 270px;
    margin-top: 50px;
    margin-left: 40px;

    :global(.leaflet-container) {
      height: 270px;
      border: calc(2px + 0.2vw) solid #0000;
    }
  }
}

.header {
  padding: 5px;
  display: flex;
  justify-content: space-between;

  a {
    vertical-align: top;
    margin-right: 10px;
    text-decoration: none !important;
  }
}

.content {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
}

.leftSection {
  width: 700px;

  h5 {
    font-weight: bold;
    margin-bottom: 15px;

    button {
      float: right;
    }
  }
}

.rightSection {
  width: 355px;

  p {
    margin: 0;
    padding: 10px 10px 10px 30px;

    span {
      display: block;
    }
  }

  .map {
    max-width: 100%;
    margin: 0 auto 25px;
    display: block;
  }
}

.subHeader {
  font-weight: bold;
  font-size: 18px;

  svg {
    margin-right: 5px;
  }
}

.linkPanel {
  display: flex;
  border: 1px solid #b3b3b3;
  padding: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.linkIcon {
  width: 60px;
}

.cropText {
  overflow: hidden;
  max-width: 400px;
  display: inline-block;
}

.cropText + svg {
  vertical-align: inherit;
}

.linkDescription {
  width: 100%;
  overflow: hidden;
  margin-right: 10px;

  h6 {
    margin-bottom: 0;
  }

  p {
    margin: 0;
  }
}

.chipCat {
  margin: 5px;
  background-color: #fff !important;
}

.paramsRow {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #b3b3b3;

  &:first-child {
    border-top: 1px solid #b3b3b3;
  }

  &:nth-child(odd) {
    background-color: #efefef;
  }

  > div:first-child {
    width: 250px;
    flex-shrink: 0;
    font-weight: bold;
  }

  > div:last-child {
    word-break: break-word;
  }

  p {
    margin-bottom: 6px;

    &:last-child {
      margin: 0;
    }
  }
}

.searchButton {
  cursor: pointer;
  margin-left: 2px;
}

.keyWord {
  margin-right: 7px;
  white-space: nowrap;
}

.overflowBreak {
  overflow-wrap: break-word;
}

.keywordsWrapper {
  display: flex;
  flex-wrap: wrap;
}
