.root {
  display: block;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
}

.table {
  display: table;
  width: 100%;

  table-layout: fixed;
}

.row {
  display: table-row;
  width: 100%;
}

.cell {
  $padding: 1rem;

  display: table-cell;

  box-sizing: border-box;
  padding: $padding 0;

  overflow: hidden;

  border-bottom: 1px solid #e9ecef;

  &:first-child {
    font-weight: 600;
    font-size: 14px;
    text-align: right;

    padding-right: $padding;
  }

  &:nth-child(2) {
    padding-left: $padding;
    width: 69%;
  }
}
