body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.spoiler .spoiler-content {
  max-height: 128px;
  padding: 5px 30px;
  overflow: hidden;
}

.spoiler.expanded .spoiler-content {
  max-height: 100%;
}

.spoiler.expanded .spoiler-show {
  display: none;
}

.spoiler .spoiler-hide {
  display: none;
}

.spoiler.expanded .spoiler-hide {
  display: inline-block;
  padding: 0 30px 5px;
}

.spoiler .spoiler-show {
  display: inline-block;
  padding: 0 30px 5px;
}

.ScrollbarsCustom .ScrollbarsCustom-Wrapper {
  inset: 0 !important;
}
.ScrollbarsCustom .ScrollbarsCustom-Content {
  padding: 0 !important;
}
.ScrollbarsCustom .trackYProps {
  top: 2px !important;
  width: 5px !important;
  height: calc(100% - 4px) !important;
  background: #0000000f !important;
}

.ScrollbarsCustom .thumbYProps {
  background: #5ca6ff !important;
}

.link {
  color: #3277b3;
  cursor: pointer;
  text-decoration: underline;
}

.link:hover {
  text-decoration: none;
}

.dropdown {
  display: inline-block;
}

.page {
  height: 100%;
}

.page-padding {
  padding: 10px;
}

.styleless-link {
  color: #fff;
}

.styleless-link:hover {
  color: #fff;
  text-decoration: underline;
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

.button-success {
  background: #51b129 !important;
  color: #fff !important;
}

.button-success:hover {
  background: #479e23 !important;
  color: #fff !important;
}

.button-success.Mui-disabled {
  background: #cacaca !important;
}

.button-danger {
  background: #d83333 !important;
  color: #fff !important;
}

.button-danger:hover {
  background: #a72d2d !important;
  color: #fff !important;
}

.button-danger.Mui-disabled {
  background: #cacaca !important;
}

#root .MuiTableRow-root.Mui-selected,
#root .MuiTableRow-root.Mui-selected:hover {
  background-color: #cee7f7;
}

.MuiDialog-paperWidthSm {
  max-width: 100% !important;
}

.MuiDialogContent-root {
  padding-bottom: 15px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

.bg-green {
  background-color: #6adc4b !important;
}

.bg-red {
  background-color: #ef8181 !important;
}
