.schedule-range {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cfe7ff;
  padding: 5px;
  border-radius: 5px;
  background-color: #f3f9ff;
  margin-bottom: 10px;
}
