.filtersHeader {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 15px;
}

.filtersHeaderButtons {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.filtersHeader h5 {
  margin: 0;
  margin-right: 15px;
}

.filterTitle {
  padding-left: 10px;
  margin-bottom: 0;
  margin-top: 10px;
}

.filterTitle svg {
  vertical-align: text-bottom;
  margin-right: 5px;
  transition: transform 0.4s;
}

.filterTitleOpen svg {
  transform: rotate(90deg);
}

.params {
  overflow: visible !important;
  padding: 15px;
  position: absolute;
  width: 98%;
  z-index: 1000;
}

.inputWrapper {
  display: flex;
  justify-content: space-evenly;
}

.params .innerParams {
  display: none;
  padding-top: 10px;
  margin: 0px !important;
  margin-bottom: -15px !important;
}

.params .innerShow {
  display: flex;
}

.selectWrapper {
  margin-bottom: 10px;
}

.selectLabel {
  margin-bottom: 5px;
  font-weight: bold;
}

.dateLabel {
  margin-bottom: 15px;
  margin-top: -9px;
  font-weight: bold;
}

.mapButton {
  position: absolute !important;
  bottom: 12px;
  right: 12px;
}

:global(.mapWrapper) {
  position: absolute;
  width: 560px;
  height: 560px;
  bottom: 10px;
  right: 10px;
  z-index: 1100;
  box-shadow: 0 2px 8px rgb(18 17 36 / 4%), 0 4px 32px rgb(18 17 36 / 8%);
  background-image: linear-gradient(to bottom right, #2b86c5, #562b7c);
  /* background-image: linear-gradient(to bottom right, #ff3cac, #562b7c, #2b86c5); */
}

:global .mapWrapper .leaflet-container {
  height: 560px;
  border: calc(5px + 0.2vw) solid #0000;
  background-clip: content-box, border-box;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 10%);
}

.mapCloseButton {
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  top: 14px;
  right: 14px;
  background: #fff;
  z-index: 1000;
  border-radius: 50%;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 2px 8px rgb(18 17 36 / 4%), 0 4px 32px rgb(18 17 36 / 8%);
}

.mapSelectButton:hover,
.mapDrawButton:hover,
.mapCloseButton:hover {
  background-color: #e4e1e1;
}

.mapDrawButton {
  width: 1.6em;
  height: 1.6em;
  position: absolute;
  top: 14px;
  left: 14px;
  background: #fff;
  z-index: 1000;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 2px 8px rgb(18 17 36 / 4%), 0 4px 32px rgb(18 17 36 / 8%);
}

.mapDrawButton_active,
.mapDrawButton_active:hover {
  background: #1a76d2;
  color: #fff;
}

.mapSelectButton {
  margin-left: 0.2em;
  width: 1em;
  height: 1.6em;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 2px 8px rgb(18 17 36 / 4%), 0 4px 32px rgb(18 17 36 / 8%);
}

.mapSelectButton :global(svg) {
  margin-left: -4px;
}

.mapDropdown {
  display: inline-block;
  position: relative;
  left: 39px;
  top: 14px;
  z-index: 1200;
}

.listHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
}

.itemIcon {
  text-align: center;
  width: 100px;
  display: block;
  flex-shrink: 0;
  padding-top: 30px;
}

.badge {
  right: -3px !important;
  top: 21px !important;
  border: 2px solid #fff;
  padding: 0 4px;
}

.itemTitle {
  font-size: 20px;
  font-weight: 600;
}

.itemCat {
  text-align: right;
}

.itemContent {
  width: 100%;
  padding-right: 160px;
  border-bottom: 1px solid #e2e2e2;
  word-break: break-word;
  min-height: 140px;
}

.itemFooter {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  align-items: center;
  margin-bottom: 5px;
}

.itemDescription {
  min-height: 35px;
}

:global(#root) .activatedButton {
  border-color: #ff9a00;
  /* box-shadow: 0px 0px 10px 0px #f9c270; */
  color: #ff9a00;
}

.autosuggest {
  width: 100%;
  position: relative;
}

.clearInput {
  position: absolute;
  color: #909090;
  top: 4px;
  right: 4px;
  cursor: pointer;
}

.clearInput:hover {
  color: #525252;
}

/* border: calc(8px + 0.2vw) solid transparent;
	background-origin: border-box;
	background-clip: content-box, border-box;
	background-size: cover;
	box-sizing: border-box;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
	background-image: linear-gradient(to bottom right, #ff3cac, #562b7c, #2b86c5); */

.ram__wrapper {
  min-width: 800px;
  height: 65vh;
  margin-bottom: 10px;
}

.ram__title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
  text-align: center;
}

.ram__id {
  text-align: center;
  font-style: italic;
}

.ram__content {
  display: flex;
  justify-content: space-between;
  height: 45vh;
}

.ram__contentBlock {
  width: 48%;
  position: relative;
}

.ram__contentHeader {
  height: 48px;
  line-height: 48px;
}

.ram__contentHeader :global(.MuiTab-wrapper) {
  text-transform: capitalize;
}

.ram__contentBody {
  border: 2px solid #bfdefd;
  border-radius: 4px;
  padding: 10px;
  height: 100%;
}

.ram__listItem {
  background: whitesmoke;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: all 0.2s;
}

.ram__listItem:hover:not(.ram__listItemActive) {
  background: #e2f0ff;
}

.ram__listItemActive {
  background: rgb(158 205 254);
  cursor: default;
}
