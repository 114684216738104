.showcase {
  display: flex;
  flex-wrap: wrap;
  padding-top: 200px;
  justify-content: space-between;
  margin: 0 auto;
  height: fit-content;
}

.showcaseItem {
  margin: 15px;
}

.chipCat {
  margin: 5px;
  background-color: #fff !important;
}
